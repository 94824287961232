body {
    color: $black;
    background-position: 20px 0;
    background: repeating-linear-gradient(
    to right, #fff 50px, #fff 50px, #F5F8FA 50px, #F5F8FA 51px, #fff 52px, #fff 449px)
}

[v-cloak] {
    display: none;
}

.main-container {
    overflow: hidden;
}

.intro {
    background: $color-light-gray;
    position: relative;
    transform-style: preserve3d;
    perspective: 100px;
    padding: 4rem $global-baseline + px 0 $global-baseline + px;
    overflow: hidden;

    .max-width-container {
        padding-bottom: 4rem;
        max-width: 66rem;
    }

    &:after {
        background: $white;
        content: "";
        height: 20rem;
        left: 0px;
        position: absolute;
        right: -4px;
        transform-origin: 0 0;
        transform: rotate(-4deg);
        z-index: 0;
        background: repeating-linear-gradient(
        94deg,
        #fff 50px, #fff 50px, #F5F8FA 50px, #F5F8FA 51px, #fff 52px, #fff 449px);
    }
}

a {
    color: $color-brand;
    transition: all .1s ease-in-out;

    img {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
    }

    &:hover {
        img {
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0);
        }
    }
}

.styled-list {
    list-style-type: none;

    li {
        position: relative;
        margin-left: 1rem;
        padding: 0 0 0.5rem 0;
        &:before {
            content: "";
            position: absolute;
            left: -1rem;
            top: 50%;
            margin-top: -6.5px;
            height: 7px;
            width: 7px;
            background: $color-brand-orange;
        }
    }
}

ul,
ol {
    list-style-position: outside;
    margin-left: 20px;
}

dt {
    font-family: $font-heading;
    font-weight: 500;
}

dd {
    margin-bottom: $global-baseline + px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-heading;
    text-transform: uppercase;
    font-weight: 600;
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
}

.pad-top {
    padding: 24px 0 0 0;
}

.shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
}

.hidden {
    position: absolute;
    left: -9999em;
}

.max-width-container {
    max-width: $max-width;
    margin: 0 auto;
    position: relative;
}

hr {
    border: 0;
    background: $color-light-gray;
    clear: both;
    display: block;
    height: 1px;
    margin-bottom: $global-baseline + px;
    margin-top: $global-baseline + px;
    width: 100%;

    @media screen and (min-width: 64em) {

        margin-bottom: $global-baseline * 3 + px;
        margin-top: $global-baseline * 3 + px;

    }
}

em {
    font-family: $font-main;
    font-style: italic;
}

.page-break {
    background-color: $color-brand;
    border: 0;
    height: 1px;
    margin-bottom: -1px;
    width: 100%;
}

.split-bg {
    background-color: $white;
    position: relative;

    .content {
        position: relative;
        z-index: 2;
    }

    .top {
        background-color: gray;
        position: absolute;
        top: 0;
        height: 50%;
        width: 100%;
        z-index: 1;
    }
}

.tag {
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
    &.pink {
        color: $color-brand-pink;
    }

    &.purple {
        color: $color-brand-purple;
    }

    &.orange {
        color: $color-brand-orange;
    }
}

.gray {
    background: $color-light-gray;
}

.wrapper {
    padding: $global-baseline + px;

    &.gray {
        margin: 0 $global-baseline + px;
    }
}

.no-margin-top {
    margin-top: 0;
}

.no-margin-left {
    margin-left: 0;
}

.no-margin-right {
    margin-right: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.no-padding-top {
    padding-top: 0;
}

.no-padding-left {
    padding-left: 0;
}

.no-padding-right {
    padding-right: 0;
}

.no-padding-bottom {
    padding-bottom: 0;
}
@include mediaQuery(map-get($grid-breakpoints, navigation-break)) {
     body {

     }
}


@keyframes fade-slide-down {
    0% {
        opacity: 0;
        transform: translateY(-1000rem);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}