.compare-programs {
    .nutrition-plans-intro {
        position: relative;
        transform-style: preserve3d;
        perspective: 100px;
        padding: 4rem $global-baseline + px 0 $global-baseline + px;
        overflow: hidden;
        min-height: 38rem;

        .angle-bkgd {
            position: absolute;
            background: $color-light-gray;
            height: 100%;
            left: -20%;
            right: -10%;
            top: -80%;
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            .grid {
                grid-template-columns: 38% auto;

                .slider {
                    align-self: center;
                }
            }

            .angle-bkgd {
                transform: rotate(-4deg);
                top: -78%;
            }
        }
    }

    .nutrition-options {
        position: relative;
        .mobile {
            display: block;
        }

        .select-container {
            margin-bottom: 2rem;
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            background: $white;
            position: relative;
            box-shadow: 0 7px 12px 0 rgba(0,0,0,0.11);
            margin: 0 auto;
            max-width: 70rem;

            h2 {
                padding: 1rem 0 0 1rem;
            }

            .mobile {
                display: none;
            }
        }
    }

    .compare-options-listing {
        .col {
            &.header {
                display: grid;
                background: $color-light-gray;
                font-family: $font-heading;
                font-size: 0.95rem;
                font-weight: 600;
                align-self: stretch;
                padding: 1rem;

                &.npa, &.bnp, &.ooo {
                    display: none;

                    &.active {
                        display: grid;
                    }
                }

                .includes {
                    align-self: end;
                    font-size: 1.1rem;
                }

                .plan {
                    font-size: 1rem;
                    text-transform: uppercase;
                    text-align: center;
                    align-self: start;
                    z-index:100;

                    #compare-options-picklist {
                        background: none;
                        border: none;
                        border-radius: 0;
                        height: auto;
                        padding: 0 2.5rem 0 0;
                        word-wrap: normal !important;
                        white-space: normal;
                    }


                    .mobile {
                        display: block;
                    }

                    .desktop {
                        display: none;
                    }

                }
            }

            &.feature {
                padding: 1rem;
            }

            &.included {
                text-align: center;
                display: grid;

                &.npa, &.bnp, &.ooo {
                    display: none;

                    &.active {
                        display: grid;
                    }
                }

                .icon {
                    font-size: 1.5rem;
                    align-self: center;
                    color: $color-medium-gray;

                    &.filled {
                        color: $color-brand-purple;
                    }
                }
            }

            &.gray {
                background: $color-light-gray;
            }

            &.ooo {
                position: relative;
                padding: 1rem;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: -4px;
                    bottom: -4px;
                    width: 100%;
                    box-shadow: 10px 0 4px -4px rgba(0,0,0,0.05) , -10px 0 4px -4px rgba(0,0,0,0.05);
                }

                &.footer {
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: -4px;
                        width: 104%;
                        height: 100%;
                        box-shadow: 0 10px 4px -4px rgba(0,0,0,0.05);
                    }
                }

                .btn-accent {
                    position: relative;
                    z-index: 100;
                }
            }

            &.npa {
                padding: 1rem;
            }

            &.bnp {
                padding: 1rem;
            }

            &.footer {
                padding: 1rem;
                text-align: center;

                &.npa, &.bnp, &.ooo {
                    display: none;

                    &.active {
                        display: grid;
                    }
                }

                &.pricing {
                    font-family: $font-heading;
                    font-size: 1.1rem;
                    font-weight: 600;
                    text-align: left;
                }

                h5 {
                    margin: 0;
                    font-family: $font-main;
                    font-weight: 400;
                    font-size: 2rem;
                }

                .disclaimer {
                    font-style: italic;
                    color: $color-medium-dark-gray;
                    margin-bottom: 0.5rem;
                }

                .branded-link {
                    color: $color-brand;
                }

            }
        }

        .grid {
            display: grid;
            grid-template-columns: 60% 40%;
            grid-gap: 0;
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {

            .col {

                &.header {

                    .plan {
                        .mobile {
                            display: none;
                        }

                        .desktop {
                            display: block;
                        }
                    }

                    &.npa, &.bnp, &.ooo {
                        display: grid;
                    }
                }

                &.included {
                    &.npa, &.bnp, &.ooo {
                        display: grid;
                    }
                }

                &.footer {
                    &.ooo {
                        display: grid;

                        .btn-accent {
                            display: block;
                            align-self: end;
                        }
                    }

                    &.npa, &.bnp {
                        display: block;

                        &.active {
                            display: block;
                        }
                    }
                }
            }

            .grid {
                display: grid;
                grid-template-columns: 40% 20% 20% 20%;
                grid-gap: 0

            }
        }

        @include mediaQuery(map-get($grid-breakpoints, med-large)) {
            margin: 0 auto 3rem auto;
        }
    }
}


.nutrition-plans {
    .programs-content-layout {
        margin-top: -5rem;
    }

    .max-width-wrapper {
        margin: 0 auto;
        max-width: 66rem;
        position: relative;
    }


    .plan-includes {
        h5 {
            margin-top: 0;
        }

        .grid {
            grid-template-columns: 48% 48%;
            grid-gap: 4%;
        }

        .mobile {
            display: block;
        }

        .desktop {
            display: none;
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {

            .mobile {
                display: none;
            }

            .desktop {
                display: block;
            }
        }
    }

    .self-guided-programs {
        .block .block-header {
            grid-template-columns: 2% 23% 30% 23% 2%;
            margin-bottom: 2rem;

            h2 {
                margin: 0;
            }
        }

        .intro-content {
            padding: 0 2rem;
            margin: 0 auto;
            text-align: center;

            @include mediaQuery(map-get($grid-breakpoints, medium)) {
                max-width: 50%;
            }
        }
    }

    .self-guided-programs {
        .intro-content {
            padding: 0 2rem;
            margin: 0 auto;
            text-align: center;

            @include mediaQuery(map-get($grid-breakpoints, medium)) {
                max-width: 60%;
            }
        }

        .plan-info {
            margin-top: 1rem;
            padding: 0 1rem;
            background: $color-light-gray;

            h3 {
                font-size: 1.5rem;
                padding: 0 0 1rem 1rem;
                position: relative;
                margin-top: 1rem;
                margin-bottom: 0;
                a {
                    color: $black;
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 15px;
                    width: 0;
                    height: 0;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-left: 5px solid $color-brand;
                }
            }
        }

        .pricing {
            text-align: center;
            margin-bottom: 1rem;
            h6 {
                margin: 0 0 1rem 0;

                &.no-margin {
                    margin: 0;
                }
            }
            h5 {
                margin: 0;
                font-family: $font-main;
                font-weight: 400;
                font-size: 2.5rem;

            }

            .disclaimer {
                font-style: italic;
                color: $color-medium-dark-gray;
                margin-bottom: 1rem;
            }
        }

        .accordion {
            padding: 0 1rem;

            h3 {
                margin-top: 1rem;
            }
            .faq {

                h5 {
                    text-transform: capitalize;
                    font-size: 1.25rem;
                    margin-bottom: 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                p {
                    margin-top: 0;
                }
            }
        }

        .nutrition-option-includes {
            padding: 0;
            list-style: none;
            margin: 0 0 1rem 0;

            li {
                position: relative;
                padding: 1rem 1rem 1rem 3rem;

                &:before {
                    content: "\e90d";
                    font-family: 'm2performancenutrition' !important;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    font-size: 1.25rem;
                    color: $color-brand-purple;
                    position: absolute;
                    left: 1rem;
                    top: 18px;

                }
            }

            li:nth-child(odd) {
                background: $color-light-gray;
            }
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {

            .grid {
                display: grid;
            }

            .pricing {
                text-align: left;

                .action {
                    align-self: center;
                    justify-self: end;
                }
            }

            .accordion {

                &.override {
                    h3 {
                        display: none;
                    }

                    .content {
                        display: block;
                    }
                }
            }
        }
    }

    .nutrition-option-listing {
        margin-top: -2rem;

        .plan-type {
            text-align: center;
            padding: 2rem 0 0.25rem;
            .icon {
                color: $color-brand-orange;
            };

            font-size: 1rem;
            text-transform: uppercase;
            font-family: $font-heading;
            font-weight: 600;
            color: $color-brand-orange;
        }

        .block .block-header {
            grid-template-columns: 2% 23% 30% 23% 2%;
            margin-bottom: 2rem;

            h3 {
                margin: 0;
            }
        }

        .intro-content {
            padding: 0 2rem;
            margin: 0 auto;
            text-align: center;

            @include mediaQuery(map-get($grid-breakpoints, medium)) {
                max-width: 60%;
            }
        }
        h3 {
            margin-top: 0;
        }

        .plan-info {
            margin-top: 1rem;
            padding: 1rem;
            background: $color-light-gray;

            h3 {
                font-size: 1.5rem;
                padding: 0 0 1rem 1rem;
                position: relative;
                //margin-top: 1rem;
                margin-bottom: 0;
                a {
                    color: $black;
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 15px;
                    width: 0;
                    height: 0;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-left: 5px solid $color-brand;
                }
            }
        }

        .pricing {
            text-align: center;
            margin-bottom: 1rem;
            h6 {
                margin: 0 0 1rem 0;

                &.no-margin {
                    margin: 0;
                }
            }
            h5 {
                margin: 0;
                font-family: $font-main;
                font-weight: 400;
                font-size: 2.5rem;

            }

            .disclaimer {
                font-style: italic;
                color: $color-medium-dark-gray;
                margin-bottom: 1rem;
            }
        }

        .accordion {
            @include mediaQuery(map-get($grid-breakpoints, medium)) {
                padding: 0 1rem;
                margin-top: 0;

                h3 {
                    margin-top: 0 !important;
                }
            }
            h3 {
                margin-top: 1rem;
            }
            .faq {

                h5 {
                    text-transform: capitalize;
                    font-size: 1.25rem;
                    margin-bottom: 0;
                    word-break: break-all;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                p {
                    margin-top: 0;
                }
            }
        }

        .nutrition-option-includes {
            padding: 0;
            list-style: none;
            margin: 0 0 1rem 0;

            li {
                position: relative;
                padding: 1rem 1rem 1rem 3rem;

                &:before {
                    content: "\e90d";
                    font-family: 'm2performancenutrition' !important;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    font-size: 1.25rem;
                    color: $color-brand-purple;
                    position: absolute;
                    left: 1rem;
                    top: 18px;

                }
            }

            li:nth-child(odd) {
                background: $color-light-gray;
            }
        }

        @include mediaQuery(map-get($grid-breakpoints, medium)) {
            background: $white;
            position: relative;
            box-shadow: 0 7px 12px 0 rgba(0,0,0,0.11);
            margin: -3rem 24px 3rem 24px;

            .grid {
                display: grid;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 10px;
                width: 100%;
                z-index: -1;
                box-shadow: 0 0 12px 0 rgba(0,0,0,0.11);
            }

            .pricing {
                text-align: left;

                .action {
                    align-self: center;
                    justify-self: end;
                }
            }

            .accordion {

                &.override {
                    h3 {
                        display: none;
                    }

                    .content {
                        display: block;
                    }
                }
            }
        }

        @include mediaQuery(map-get($grid-breakpoints, med-large)) {
            margin: -3rem auto 3rem auto;
        }
    }
}

.compare-plans {
    text-align: center;
    padding: 0 2rem 1rem;
    margin: 0 auto;
    text-transform: uppercase;

    a {
        font-family: $font-heading;
        font-size: 1.5rem;
    }
}