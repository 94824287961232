%header-animation {
    transition: all 0.2s ease-in-out;
}

.general, .coaches, .client-stories, .how-it-works, .blog, .compare-programs {
    .header-main {
        background: $color-light-gray;

        .screen, .contents {
            background: $color-light-gray;

            .join-us-mobile {
                &.blue {
                    display: none;
                }
                &.gray {
                    display: inline-block;
                }
            }

            .nav-logo {
                &.white {
                    display: none;
                }
                &.color {
                    display: block;
                }
            }

            .nav-logo-scrolled {
                display: block;
            }

            .nav-logo {
                display: none;
            }
        }

        .menu-toggle {
            color: $color-brand;
        }

        .nav-items {
            a {
                color: $black;

                &.join-us {
                    &.blue {
                        display: none;
                    }

                    &.gray {
                        display: inline-block;
                    }
                }
            }
        }

        &.active {
            @extend %boxshadow-dark;
            @extend %header-animation;

            .screen {
                background: $color-brand;

                .nav-logo {
                    display: block;
                }

                .nav-logo-scrolled {
                    display: none;
                }
            }

            .contents {
                background: $color-brand;
            }

        }

    }
}

.home, .nutrition-plans {
    .header-main {
        background: transparent;

        .screen {
            background: transparent;
        }

        @include mediaQuery(map-get($grid-breakpoints, navigation-break)) {
            .contents {
                background: transparent;
            }
        }
    }
}

.header-main {
    background: $color-brand;
    display: inline-block;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;

    a {
        img {
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }

    .nav {
        @extend %clearfix;
        display: none;
        float: left;
        font-family: $font-main;
        font-weight: 600;
        text-align: center;
        width: 100%;
    }

    .nav-items {
        @include setType(16, 0);
        @extend %scrub-list-style;

        float: left;
        width: 100%;

        a {
            color: $white;
            display: block;
            padding: $global-baseline/2 + px $global-baseline + px;
            text-decoration: none;
            vertical-align: middle;
            width: 100%;

            &.join-us {
                color: $color-brand-purple !important;
                padding: 0.5rem 1.75rem !important;

                &:hover {
                    color: $white !important;
                }

                &.blue {
                    display: inline-block;
                }
                &.gray {
                    display: none;
                    color: $white !important;
                }
            }
        }

        .sub-nav {
            display: none;
            background: $color-brand;
        }
    }

    .nav-item {
        display: block;
        vertical-align: middle;
    }

    .join-us-mobile {
        position: absolute;
        top: 15px;
        right: 4rem;
        padding: 0.5rem 1.75rem !important;

        &.blue {
            display: block;
        }

        &.gray {
            display: none;
        }
    }

    .menu-toggle {
        @extend %hover;
        color: $white;
        font-size: 1.5rem;
        position: absolute;
        top: $global-baseline + 1 + px;
        right: $global-baseline + px;

        &.active {
            color: $white;
        }
    }

    .menu {
        display: block;
        font-size: $global-baseline + px;
        height: $global-baseline + px;
        width: $global-baseline + px;

        &.icon-close {
            font-size: 1.25rem;
        }
    }

    .contents {
        @extend %header-animation;
        background: $color-brand;
        position: relative;
        z-index: 100;

        .nav-logo-container {
            display: none;
        }
    }

    .screen {
        @extend %header-animation;
        position: relative;
        z-index: 99;
        width: 100%;
        background: $color-brand;
        padding-top: 1rem;
        top: 0;
        height: $global-baseline * 3 + px;

        .nav-logo-scrolled {
            display: none;
        }

        .nav-logo-container {
            display: inline-block;
            float: left;
            padding: 12px 0 12px $global-baseline + px;
        }

        &.active {
            position: fixed;

        }
    }

    &.active {
        @extend %boxshadow-dark;
        @extend %header-animation;

        background: $color-brand;

        .nav-items {
            margin-top: 4rem;
            text-align: center;
            a {
                color: $white;
                font-size: 1.25rem;
            }

            .instagram {
                font-size: 1.5rem;
            }

            .desktop {
                display: none;
            }
        }

        .contents {
            position: fixed;
            width: 100%;
            height: 100%;
        }
    }

    &.scrolled {
        .nav-logo-container {
            opacity: 1;
        }

        .screen {
            @extend %boxshadow-dark;
            top: 0;
            background: $white;


            .nav-logo {
                display: none;
            }

            .nav-logo-scrolled {
                display: inline-block;
            }

            .menu-toggle {
                color: $color-brand;
            }
        }
    }

    &.scrolled.active {
        .screen {
            background: $color-brand;
            box-shadow: none !important;

            .nav-logo {
                display: inline-block;
            }

            .nav-logo-scrolled {
                display: none;
            }

            .menu-toggle {
                color: $white;
            }
        }
    }

    @include mediaQuery(map-get($grid-breakpoints, navigation-break)) {
        position: relative;

        .contents {
            padding: 0 1rem;
            width: 100%;
            display: inline-block;

            .nav-logo-container {
                display: inline-block;
            }
        }

        .nav {
            background: none;
            border: none;
            display: inline-block !important;
            float: right;
            margin: 0 2rem 0 10px;
            width: auto;
        }

        .nav-items {
            width: auto;

            .nav-item {
                padding: 1rem 0;
                border-top: 3px solid transparent;
                position: relative;

                &.parent {
                    a {
                        &.active {
                            background: $white;
                            border-radius: 3px 3px 0 0;
                            color: $color-brand;
                            font-weight: 300;
                            position: relative;
                            z-index: 100;
                        }
                    }

                    .sub-nav {
                        a {
                            color: $black;

                            &:hover {
                                background-color: $color-light-gray;
                            }
                        }
                    }
                }

                a {
                    //color: $white;
                    display: inline-block;
                    padding: $global-baseline/2 + px $global-baseline/1.25 + px ($global-baseline/2) - 2 + px;
                    width: auto;

                    &:hover {
                        border-color: $color-brand;
                    }
                }

                &.desktop {
                    &:hover {
                        border-color: transparent;
                    }
                }
                &:hover {
                    border-color: $color-brand-purple
                }

                &.active {
                    border-color: $color-brand-purple;

                    .sub-nav a {
                        color: $black;
                    }
                }
            }


            .sub-nav {
                display: none;
                position: absolute;
                top: 59px;
                left: -10px;
                background: $white;
                border-radius: 2px;
                width: 16rem;
                box-shadow: 0 2px 11px 0 rgba(23,44,109, 0.6);
                z-index: 50;

                &.active {
                    display: block;
                }

                ul {
                    list-style:none;
                    text-align: left;
                    font-size: 1.25rem;
                    font-weight: 400;
                    margin: 0;
                    padding: 0.5rem 0;

                    a {
                        color: $black;
                        display: block;
                        padding: 0.75rem 2rem;
                        width: 100%;

                        .icon {
                            margin-right: 0.5rem;
                            font-size: 1.5rem;
                        }

                        .icon-tips {
                            color: $color-brand-yellow;
                        }

                        .icon-health {
                            color: $color-brand-pink;
                        }

                        .icon-lifestyle {
                            color: $color-brand-purple;
                        }

                        .icon-performance {
                            color: $color-brand-orange;
                        }

                        &:hover {
                            background: $color-light-gray;
                        }
                    }
                }
            }

            .mobile {
                display: none;
            }

            .desktop {
                display: inline-block;
            }
        }

        .nav-item {
            display: inline-block;

            &.active {
                a {
                    border-color: $color-brand;
                }
            }

        }

        .nav-logo {
            width: 177px;


            &.white {
                display: block;
            }
            &.color {
                display: none;
            }
        }

        .menu-toggle, .join-us-mobile, .screen {
            display: none;
        }

        .nav-logo-container {
            padding-top: 1.5rem;
            //padding-left: 2rem;
        }
    }
}

@keyframes menu-subnav {
    0% {
        background: none;
    }

    100% {
        background: $white;
    }
}
