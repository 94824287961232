// Global
$global-browser-context: 16;
$global-baseline: 24;

// Typography
$font-main: "Roboto", sans-serif;
$font-heading: "Roboto Condensed", sans-serif;

// Max-Width
$max-width: 81.250em;

// Responsive typography map
$typography-map: (
    h1: (
        default: 24,
        small: 36,
        medium: 40,
        large: 40,
        x-large: 40
    ),
    h2: (
        default: 20,
        small: 24,
        medium: 30,
        large: 30,
        x-large: 30
    ),
    h3: (
        default: 18,
        small: 22,
        medium: 28,
        large: 28,
        x-large:28
    ),
    h4: (
        default: 16,
        small: 18,
        medium: 24,
        large: 24,
        x-large: 24
    ),
    h5: (
        default: 16,
        small: 18,
        medium: 20,
        large: 20,
        x-large: 20
    ),
    h6: (
        default: 14,
        small: 16,
        medium: 18,
        large: 18,
        x-large: 18
    ),
    p: (
        default: 16,
        small: 16,
        medium: 18,
        large: 18,
        x-large: 18
    )
);

// Layout
// Breakpoints: 600px, 800px, 1024px, 1200px
$grid-breakpoints: (
    navigation-break: 65.188em,
    small: 35.5em,
    medium: 50em,
    med-large: 73em,
    large: 83.063em,
    x-large: 120em,
);

// Color
$white: rgb(255,255,255); // #FFFFFF
$black: rgb(64, 65, 70); // #404146
$color-brand: rgb(54,102,255); // #3666FF
$color-text-main: rgb(64, 65, 70); // #404146
$color-brand-purple: rgb(219,63,211); // #DB3FD3
$color-brand-pink: rgb(255,48,142); // #FF308E
$color-brand-yellow: rgb(255,166,0); // #FFA600
$color-brand-orange: rgb(255,105,75); // #FF6B4B
$color-light-gray: rgb(236, 237, 238); // #F5F8FA
$color-medium-gray: rgba(64, 65, 70, 0.5); // #404146
$color-medium-dark-gray: rgb(112, 110, 107); // #706E6B
$color-dark-gray: rbga(64, 65, 70, 1); // #404146
